import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Button, ButtonProps } from '../button/button';
import { AttachFileIcon } from '../icons/react/attach-file';
import { VisuallyHidden } from '../visually-hidden/visually-hidden';
import { fileInput } from './file-input.css';
import clsx from 'clsx';

type FileInputProps = Pick<ButtonProps, 'size' | 'rounded' | 'variant'> &
  Omit<ComponentPropsWithoutRef<'input'>, 'type' | 'size'>;

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  function FileInput(
    {
      size = 'small',
      variant = 'outline',
      rounded = true,
      className,
      accept,
      multiple,
      children,
      disabled,
      ...props
    },
    ref,
  ) {
    return (
      <Button
        disabled={disabled}
        variant={variant}
        startIcon={<AttachFileIcon />}
        rounded={rounded}
        size={size}
        className={clsx(fileInput, className)}
        asChild
      >
        <label>
          {children}
          <VisuallyHidden>
            <input disabled={disabled} ref={ref} type="file" {...props} />
          </VisuallyHidden>
        </label>
      </Button>
    );
  },
);
